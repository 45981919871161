:root {
  --main-bg-color: #1e1e1e;
  --text-color: #f5f5f5;
  --header-height: 100vh;
  --font-family: 'Roboto', sans-serif;
  --font-weight-bold: 700;
  --transition-box-shadow: 0.3s;
  --box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  --box-shadow-hover: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
  --slide-in-animation: fadeIn 1s ease-in-out;
  --payment-box-width: 100%;
  --border-radius: 10px;
  --slide-in-duration: 1s;
  --slide-in-effect: ease-in-out;
}

body {
  margin: 0;
  font-family: var(--font-family);
  background-color: var(--main-bg-color);
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: var(--main-bg-color);
}

.login-input {
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
  border: none;
  margin-bottom: 10px;
  outline: none;
}

.login-button {
  padding: 10px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  background-color: #4a90e2;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #357ab8;
}

.App-header {
  min-height: var(--header-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-size: cover;
  position: relative;
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-size: cover;
  background-position: center;
  z-index: -1;
  filter: blur(5px) brightness(0.7);
}

.photo-section {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.photo {
  width: 100%;
  height: auto;
}

.overlay-text {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 90%;
  padding: 1em;
  background-color: rgba(255, 255, 255, 0.8);
  color: var(--main-bg-color);
  font-family: var(--font-family);
  font-size: 2em;
  font-weight: var(--font-weight-bold);
  text-transform: uppercase;
  border-radius: var(--border-radius);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.slide-in-text, .payment-box {
  background-color: rgba(255, 255, 255, 0.8);
  color: var(--text-color);
  border-radius: var(--border-radius);
  animation: var(--slide-in-animation);
  box-shadow: var(--box-shadow);
  transition: box-shadow var(--transition-box-shadow);
}

.slide-in-text {
  max-width: 750px;
  margin: 20px auto;
  padding: 20px;
  font-size: 18px;
}

.payment-boxes {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, var(--payment-box-width)));
  gap: 20px;
  justify-content: center;
  padding: 20px;
}

.payment-box:hover {
  box-shadow: var(--box-shadow-hover);
}

.payment-box img {
  width: 100%;
  height: auto;
  border-radius: var(--border-radius);
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: none;
  }
}

@keyframes sheen {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(200%);
  }
}

.sheen-effect {
  position: relative;
  overflow: hidden;
  display: inline-block;
  color: #1e1e1e;
  font-family: var(--font-family);
  font-size: 26px;
  font-weight: bold;
}

.sheen-effect::after {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 300%;
  height: 100%;
  background: linear-gradient(to right, transparent 0%, rgba(243, 22, 22, 0.75) 50%, transparent 100%);
  animation: sheen 2s infinite;
}

.fadeIn {
  animation: fadeIn var(--slide-in-duration) var(--slide-in-effect);
}

.highlight-text {
  font-family: 'Roboto';
  color: #333;
  text-align: center;
  margin-top: 20px;
  font-size: 28px;
  font-weight: bold;
  text-transform: uppercase;
}

.visible {
  display: block !important;
}

.full-width-image {
  max-width: 100%;
  height: auto;
}

@media (max-width: 768px) {
  .slide-in-text, .payment-box {
    background-color: rgba(255, 255, 255, 0.5);
    padding: 10px;
    max-width: 90%;
    margin: 1em auto;
  }

  .payment-boxes {
    grid-template-columns: 1fr;
  }

  .payment-box img {
    width: auto;
    max-width: 100%;
  }

  .sheen-effect {
    font-size: 1em;
  }

  .overlay-text {
    font-size: 1em;
  }

  .photo-section .overlay-text {
    left: 50%;
    transform: translate(-50%, -20%);
  }
}


.payment-info {
  font-family: Arial, sans-serif;
  color: #ffffff;
  background-color: #333;
  padding: 20px;
}

.payment-table {
  width: 100%;
  border-collapse: collapse;
}

.payment-table th, .payment-table td {
  text-align: left;
  padding: 8px;
}

.payment-table th {
  background-color: #555;
}

.payment-table tr:nth-child(even) {
  background-color: #474747;
}

.citation {
  cursor: pointer;
  color: #4a90e2;
  text-decoration: underline;
}

.disclosure {
  margin-top: 10px;
  background-color: #555;
  padding: 10px;
  border-radius: 5px;
}

.summary {
  margin-top: 15px;
  background-color: #555;
  padding: 10px;
  border-radius: 5px;
}



.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: #282c34;
  color: white;
}

.login-container input {
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.login-container button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  background-color: #007bff;
  color: white;
  font-size: 16px;
  cursor: pointer;
}

.login-container button:hover {
  background-color: #0056b3;
}
